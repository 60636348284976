@import url('../font/fonts.css');
@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css');

:root {
    --color-dark: #2C2C2C;
    --color-primary: #00A6FB;
    --color-secondary: #656565;
    --color-light: #F7FAFC;
    --color-white: #fff;
    --font-primary: 'Arial Nova';
}

body {
    font-family: var(--font-primary);
    font-size: 16px;
    color: var(--color-dark);
    line-height: 1.5;
}

a {
    text-decoration: none;
}

section {
    padding-top: 100px;
    padding-bottom: 100px;
}

/**** Text Colors ****/
.color-text-dark {
    color: var(--color-dark);
}

.color-text-primary {
    color: var(--color-primary);
}

.color-text-secondary {
    color: var(--color-secondary);
}

/**** Background Colors ****/
.color-dark {
    background-color: var(--color-dark);
}

.color-primary {
    background-color: var(--color-primary);
}

/**** Font Sizes ****/
.font-size-14px {
    font-size: 14px !important;
}
.font-size-20px {
    font-size: 20px !important;
}

.font-size-24px {
    font-size: 24px !important;
}

.font-size-32px {
    font-size: 32px !important;
}
.font-size-40px {
    font-size: 40px !important;
}
.font-size-64px {
    font-size: 64px !important;
}
/**** Utilities ****/
.py-100px {
    padding-top: 100px;
    padding-bottom: 100px;
}

.rounded-60px {
    border-radius: 60px;
}

.gap-10px {
    gap: 10px !important;
}

.gap-20px {
    gap: 20px !important;
}

.gap-40px {
    gap: 40px !important;
}

.mb-40px {
    margin-bottom: 40px !important;
}

.mb-60px {
    margin-bottom: 60px !important;
}
.mb-80px {
    margin-bottom: 80px !important;
}
.mb-100px {
    margin-bottom: 100px !important;
}
.mt-100px {
    margin-top: 100px !important;
}
.my-40px {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.my-80px {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
}

.separator {
    border-top: 1px solid #38444d;
    margin: 20px -16px;
}

.separator-primary {
    border-color: var(--color-primary);
}

.symbol {
    width: 82px;
    height: 82px;
    background-color: #D9D9D9;
}

.symbol.symbol-circle {
    border-radius: 82px;
}

.h-48px {
    height: 48px;
}

/**** Title Formating ****/
.title {
    margin-bottom: 60px;
}

.title .heading {
    font-size: 32px;
    font-weight: bold;
}

/**** Buttons Customized ****/

.btn.btn-pill {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    height: 51px;
    min-width: 147px;
    ;
}

.btn.btn-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn.btn-big {
    min-height: 106px;
    min-width: 334px;
    font-size: 24px;
}

.btn.btn-big.btn-pill {
    border-radius: 100px;
}

/**** Navbar Customized ****/
.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-nav .nav-link {
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding: 42px 20px !important;
}

.navbar-nav .nav-item {
    display: flex;
    align-items: center;
}

.navbar-nav .nav-item .dropdown-toggle::after {
    margin-left: 10px;
}

/**** Form Customized ****/
.form-group {
    margin-bottom: 20px;
}

.form-group .form-control {
    border: 2px solid var(--color-dark);
    margin-top: 10px;
    min-height: 59px;
}
.form-group textarea.form-control {
    border: 2px solid var(--color-dark);
    margin-top: 10px;
    min-height: 200px;
}
.input-group-pill .input-group-text,
.input-group-pill .btn {
    border-radius: 20px;
}

.input-group-pill .input-group-text,
.input-group-pill .form-control,
.input-group-pill .btn {
    height: 47px;
}

.input-group-pill .input-group-text {
    padding-left: 20px;
}

.input-group-pill .btn {
    width: 200px;
}

.form-control.form-control-pill {
    border-radius: 30px;
    border-color: var(--color-dark);
    appearance: none;

    font-size: 20px;
}

input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: #D9D9D9;
    border-radius: 30px;

}

input[type=range]::-webkit-slider-thumb {

    width: 10.28px;
    height: 33px;
    background: #00A6FB;
    border-radius: 30px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -11px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #D9D9D9;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;

    background: #D9D9D9;
    border-radius: 1.3px;

}

input[type=range]::-moz-range-thumb {

    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #2a6495;

    border-radius: 2.6px;

}

input[type=range]::-ms-fill-upper {
    background: #D9D9D9;

    border-radius: 2.6px;

}

input[type=range]::-ms-thumb {

    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
    background: #D9D9D9;
}

input[type=range]:focus::-ms-fill-upper {
    background: #D9D9D9;
}

/**** Hero ****/
.hero .hero-title {
    font-size: 64px;
    font-weight: bold;
    margin-bottom: 20px;
}

.hero .hero-content {
    font-size: 20px;
    font-weight: 100 !important;
}

/**** Service Boxes ****/
.serviceBoxes .box {
    min-height: 272px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 22px;
}

.serviceBoxes .box .figTitle {
    color: var(--color-white);
    font-size: 32px;
    margin-bottom: 20px;
}

.serviceBoxes .box .figContent {
    color: var(--color-white);
    font-size: 20px;
    line-height: 24px;
    padding: 0px 50px;
}

/**** Cards Customized ****/
.whyProAthelite .card.card-custom {
    border-radius: 0;
    margin: 0 30px;
}
/**** Start Change 14 Nov ****/
.whyProAthelite .card.card-custom.card-stretch{
    align-items: stretch;
    height: 100%;
}
/**** End Change 14 Nov ****/
.whyProAthelite .card.card-custom .card-body {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.whyProAthelite .card.card-custom .card-title {
    font-size: 32px;
    margin: 0;
}

.whyProAthelite .card.card-custom .card-content {
    font-size: 16px;
}

/**** Featured ****/
.featured .card.card-custom {
    border: none;
    padding: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 32px; */
}

.featured .card.card-custom .card-icon {
    margin-bottom: 30px;
    min-height: 200px;
    min-width: 180px;
    background-color: #D0D0D000;
    border-radius: 3px;
    overflow: hidden;
}

/* .featured .card.card-custom .card-icon span {
    width: 156px;
    height: 156px;
    border-radius: 3px;
    overflow: hidden;
    background-color: var(--color-light);
    display: inline-block;
} */

.featured .card.card-custom .card-title {
    font-size: 32px;
    margin-bottom: 10px;
}

.featured .card.card.card-custom .card-content {
    font-size: 16px;
    gap: 10px;
    display: flex;
    justify-content: center;
}

.featured .card.card.card-custom .card-content .label {
    padding: 4px 14px;
    display: inline-block;
    border-radius: 14px;
    font-size: 11px;
}

.featured .swiper-wrapper {
    width: 90%;
    margin: 0 auto;
}

.featured .swiper-button-next,
.featured .swiper-button-prev {
    color: var(--color-dark);
    font-size: 16px;
}

.featured .swiper-button-next::after,
.featured .swiper-button-prev::after {
    color: var(--color-dark);
    font-size: 22px;
}

/**** Discounts ****/
.discount .card.card-custom {
    border: none;
    padding: 30px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    gap: 20px;
}

.discount .card.card-custom .card-icon span {
    width: 240px;
    height: 180px;
    border-radius: 20px;
    background-color: #D9D9D9;
    display: inline-block;
}

.discount .card.card-custom .card-title {
    font-size: 32px;
    margin: 0;
    line-height: 1.25em;
}

.discount .card.card.card-custom .card-content {
    font-size: 16px;
}

.discount .swiper-wrapper {
    width: 90%;
    margin: 0 auto;
}

.discount .swiper-button-next,
.discount .swiper-button-prev {
    color: var(--color-white);
    font-size: 16px;
}

.discount .swiper-button-next::after,
.discount .swiper-button-prev::after {
    color: var(--color-white);
    font-size: 22px;
}

/**** Footer ****/
footer {
    padding: 200px 0 100px;
    background-color: var(--color-dark);
}

footer h3 {
    font-size: 40px;
}

/**** Register ****/
.register .nav-pills .nav-link {
    width: 244px;
    height: 64px;
    padding: 20px 80px;
    border: 2px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 0px 30px 0px 0px;
    border-bottom: none;
}

.register .nav-pills .nav-item:first-child .nav-link {
    border-radius: 30px 0px 0px 0px;
}

.register .nav-pills .nav-link.active {
    color: var(--color-white);
    background-color: var(--color-primary);
}

.register .tab-content {
    border: 2px solid #00A6FB;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 60px 15px;
}

/**** Plans ****/
.card-plan {
    padding: 100px 80px;
    margin: 0 50px;
}

.card-left {
    border: 6px solid var(--color-dark);
    border-radius: 0px 40px;
}

.card-right {
    border: 6px solid var(--color-primary);
    border-radius: 40px 0px;
}

.planServiceList {
    list-style: none;
    padding: 0;
    font-size: 20px;
    margin-bottom: 40px;
    line-height: 36px;
}

.planServiceList i {
    font-size: 14px;
    width: 24px;
    display: inline-block;
    text-align: center;
}

.planServiceList i.fi-rr-check {
    color: #0AB926;
}

.planServiceList i.fi-rr-cross-small {
    color: #F00B0B;
}

.card-plan .btn {
    width: 192px;
    height: 76px;
    font-size: 20px;
}

.card-plan.card-left .btn {
    border: 3px solid #2C2C2C;
    font-weight: bold;
}

.card-plan.card-right .btn {
    background: linear-gradient(98.9deg, #00A6FB 0.23%, #0044C9 100.23%);
    font-weight: bold;
}

.trainerProfile .form-group {
    margin-bottom: 40px;
}

.trainerProfile .form-group label {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
}

.trainerProfile .form-group .form-control {
    border-radius: 10px;
    font-weight: 400;
    font-size: 20px;
}

.trainerProfile .form-group .form-control::placeholder {
    font-weight: 400;
    font-size: 20px;
    color: var(--color-dark);
}

.trainerProfile .form-group textarea.form-control {
    min-height: 320px;
    margin-top: 0;
}

.trainerProfile .form-group .input-group .input-group-text {
    border-color: var(--color-dark);
    border-width: 2px;
    min-height: 59px;
}

.trainerProfile .form-group .input-group .form-control {
    margin-top: 0;
}

.uploadFileWrapper {
    position: relative;
}

.uploadFileWrapper input {
    opacity: 0;
}

.uploadFileWrapper label {
    width: 300px;
    height: 200px;
    background: #D0D0D0;
    border: 2px solid #D0D0D0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#clientHighlight .swiper-wrapper {
    width: 100%;
}

#clientHighlight .card.card-custom {
    background-color: #EDEDED;
    width: 100%;
}

#clientHighlight .card.card-custom .card-icon span {
    width: 180px;
    height: 150px;
    background: #D0D0D0;
    border-radius: 0;
}
/**** Change 14 Nov ****/
.contactForm .form-control::placeholder{
    color: var( --color-dark);
}
@media (max-width: 767px) {
    .navbar{padding: 5px 0;}
    .navbar-dark .navbar-brand{max-width: 120px;}
    .order-xs-1{order: 1;}
    .order-xs-2{order: 2;}
    .order-xs-3{order: 3;}
    .navbar-collapse{position: absolute;background: var(--color-dark);width: 100%;left: 0;top: 50px;}
    .navbar-nav .nav-item{justify-content: center;}
    .navbar-nav .nav-link{padding: 15px 20px !important;}
    .btn.btn-pill{height: 38px;min-width: 100px}
    .hero .hero-title{font-size: 32px;margin-top: 20px;}
    .hero .btn, .select-wrapper{margin-bottom: 20px;}
    .serviceBoxes .box{margin: 5px;border-radius: 15px;padding: 10px;}
    .whyProAthelite .card.card-custom.card-stretch{margin-bottom: 15px;height: auto;}
    .serviceBoxes .card-custom-img,
    .serviceBoxes .card-custom-img img{width: 100%;}
    .register .nav-pills .nav-link{width: 165px;padding: 20px 50px;}
    .serviceBoxes img{max-width: 100%;}
    .card-plan{padding: 30px 10px;margin: 0 0px 15px;}
    .card-left, .card-right{border-radius: 0;}
    .serviceBoxes .box .figContent{padding: 0 5px;}
    .discount .btn.btn-pill {width: 140px !important;height: 45px !important;font-size: 11px !important;}
    .discount .card .card-title {font-size: 20px !important;}
    .discount .card.card-custom .card-icon span {width: 140px !important; height: 150px !important;}
    .discount .card { min-height: 250px !important;}
}
/**** 18 Nov ****/
.serviceBoxes .box{
    min-height: 227px;
}
.whyProAthelite .card.card-custom .card-title,
.discount .card.card-custom .card-title{
    font-size: 38px;
}
.whyProAthelite .card.card-custom .card-content{
    font-size: 20px;
}

.btn.btn-pill{
    font-size: 18px;
}
.font-size-30px{
    font-size: 30px !important;
}
.btn.hero-btn{
    padding: 20px 60px;
    border-radius: 30px;
    width: 251px;
height: 74px;
}
.footer-title{
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 25px;
}
footer p{
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 10px;
}

footer a{
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 20px;
}
.gap-25px{
    gap: 25px !important;
}
footer{
    padding: 100px 0 100px;
}
.whyProAthelite .card.card-custom,
.discount .card.card-custom{
    border-radius: 20px;
}
.serviceBoxes .box .figTitle{
    font-weight: 700;
}
.pt-40px{
    padding-top: 40px !important;
}
.hero .hero-title{
    margin-bottom: 30px;
}
.hero-sub-title, .hero h4{
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 100;
}

.hero h4 span{
    font-weight: 600;
}

.hero h4 b{
    font-size: 24px;
}
.hero .hero-content{
    font-size: 18px;
    margin-bottom: 30px;
}
.btn.hero-btn{
    font-weight: 700;
    padding: 0;
    width: 242px;
    height: 72px;
    border-radius: 30px;

}
.font-size-50px{
    font-size: 50px !important;
}
.font-size-60px{
    font-size: 60px !important;
}
.font-weight-700{
    font-weight: 700 !important;
}
.whyProAthelite .card.card-custom.card-stretch{
    background: #F7FAFC;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}
.featured .card.card-custom{
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    height: 418px;
}
#featuredSwiper{
    padding: 30px 0;
}
#featuredSwiper .swiper-slide{
    width: 325px !important;
    display: flex;
    justify-content: center;
    margin: 0 15px;
}
.featured .swiper-wrapper{
    display: flex;
    justify-content: center;
}
.featured .card.card-custom .card-title{
    line-height: 37px;
}
.whyProAthelite .card.card-custom .card-content{
    font-size: 16px;
}
#discountSwiper .swiper-wrapper{
    display: flex;
    justify-content: center;
}
#discountSwiper .swiper-slide{
    width: 320px !important;
    height: 500px;
    margin: 0 15px;
}
.discount .card.card-custom .card-title {
    font-size: 32px;
}
.discount .card.card.card-custom .card-content .btn.btn-pill{
    border-radius: 30px;
    width: 227px;
    height: 68px;
    font-weight: 700;
    font-size: 24px;
}
.navbar .btn.btn-pill{
    font-size: 22px;
    font-weight: 700;
    width: 167px;
height: 57px;

}

.discountBox .card{
    min-height: 500px !important;
    height: 300px !important;
}


.arrow img{
    position: relative !important;
    top: 50% !important;
  }

.arrow img{
position: relative !important;
top: 50% !important;
}
.select-wrapper{
    position: relative;
    /* display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    border: 1px solid;
    border-color: var(--color-dark);
    appearance: none;
    font-size: 20px;
    justify-content: center; */
}
.select-wrapper select{
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 2rem center;
    background-size: 1em;
}
.select-wrapper.rating select{
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 5rem center;
    background-size: 1em;
}
/* .select-wrapper .form-control.form-control-pill{
    border-color: transparent;
    width: auto;
    padding: 0;
} */
.serviceBoxes .card .card-custom-img{
    max-width: 220px;
    max-height: 220px;
    width: 100% !important;
}
.serviceBoxes .card{
    margin-bottom: 60px;
}
.serviceBoxes .card .card-custom-img img{
    width: 100%;
    height: 220px;
    object-fit: cover;
}
@media (max-width: 767px){
    html, body{
        height: 100%;
        width: 100%;
        overflow-x: hidden;
    }
    .navbar .btn.btn-pill{
        font-size: 16px;
        width: auto;
        height: auto;
    }
    footer img.img-fluid{
        max-width: 75%;
    }
    .p-3-sm{
        padding: 1rem!important;
    }
    .btn.hero-btn {
        font-weight: 700;
        padding: 10px 30px;
        width: auto;
        height: auto;
        border-radius: 30px;
        font-size: 12px !important;
    }
    .title .heading{
        font-size: 32px !important;
    }
    .biYdFZ{
        margin: 0;
    }
    .discount .card.card-custom .card-title{
        min-height: 1px !important;
    }
    .input-group-pill .btn {
        width: 100px;
    }
    .serviceBoxes .card .card-custom-img img{
        height: 130px;
    }
    /* .serviceBoxes .col-lg-6{
        max-width: 50%;
    } */
    
    .serviceBoxes .col-lg-6 .card-body{
        padding: 0;
    }
    .serviceBoxes .col-lg-6 .card{
        margin-bottom: 40px;
    }
    .serviceBoxes .col-lg-6 .card .m-0.mb-3{
        min-height: 120px;
    }
    .serviceBoxes .col-lg-6 .card-body .d-flex{
        flex-direction: column;
    }
    .serviceBoxes .card .card-custom-img{
        max-width: 100%;
    }
    .navbar-collapse{
        z-index: 100;
    }
}



  .btn-pay {
    display: block;
    align-items: center;
    width: 50%;
    border: none;
    background: linear-gradient(
      135deg,
      rgb(49, 0, 62) 0%,
      rgb(195, 40, 110) 100%
    );
    color: #fff;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
  }

.cursor-pointer {
    cursor: pointer;
}

.text-sm {
    font-size: 14px !important;
}

.text-md {
    font-size: 18px !important;
}

.text-lg {
    font-size: 24px !important;
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-semibold {
    font-weight: 500;
}

.font-bold {
    font-weight: 700;
}

.w-full {
    width: 100% !important;
}