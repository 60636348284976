.editing .form-control {
  /* pointer-events: none;    */
  cursor: not-allowed;
  /* background-color: red; */
}

#rating_toggle {
  cursor: pointer;
}
#rating_toggle img {
  transform: rotate(180deg);
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 10%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: ".";
  background-image: url("./assets/img/icons/editIcon.png");
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  background-position: center;
  right: 0;
  text-align: center;
  margin: auto;
  background-size: cover;
}
.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 10%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.discount .rec.rec-arrow {
  border-radius: 0;
  background-color: transparent;
  box-shadow: 0 0 0px 0px #333;
  color: #fff;
  font-size: 28px;
}

/* round buttons on hover */
.discount .rec.rec-arrow:hover {
  border-radius: 50%;
  border-radius: 0;
  background-color: transparent;
  box-shadow: 0 0 0px 0px #333;
  color: #fff;
  font-size: 28px;
}

.featured .rec.rec-arrow {
  border-radius: 0;
  background-color: transparent;
  box-shadow: 0 0 0px 0px #333;
  color: black;
  font-size: 28px;
}
/* round buttons on hover */
.featured .rec.rec-arrow:hover {
  border-radius: 50%;
  border-radius: 0;
  background-color: transparent;
  box-shadow: 0 0 0px 0px #333;
  color: black;
  font-size: 28px;
}
